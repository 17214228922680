<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0"
      ><v-row align-content="center" justify="center" class="mx-0">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="mb_max_width">Treatments</span>
              <v-spacer class="mb_hidden"></v-spacer>

              <v-switch
                class="mt-0 pt-0 mr-6 ml-1"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                v-if="allowed_operations == 'WRITE'"
                hide-details
                id="btn_edit_treatment"
              ></v-switch>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      class="mt-0 pt-0 mr-6"
                      v-model="editable"
                      label="Edit"
                      color="primary"
                      value="Edit"
                      inset
                      disabled
                      hide-details
                    ></v-switch>
                  </div>
                </template>
                <span>You don't have permission to edit this data</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    @click="download_csv"
                    large
                    color="primary"
                    >mdi-download-circle</v-icon
                  >
                </template>
                <span> Download CSV</span>
              </v-tooltip> -->
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    data-cy="btn_livestock_add_animal"
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      allowed_operations.includes('CameraRead') ||
                      allowed_operations == 'All'
                    "
                    class="mt-0 pt-0 mr-4"
                    @click="show_heads = !show_heads"
                    large
                    color="primary"
                    >{{ show_heads ? "mdi-image-off" : "mdi-image" }}</v-icon
                  >
                  <div v-else v-bind="attrs" v-on="on">
                    <v-icon
                      disabled
                      class="mt-0 pt-0 mr-4"
                      @click="show_heads = !show_heads"
                      large
                      color="primary"
                      >{{ show_heads ? "mdi-image-off" : "mdi-image" }}</v-icon
                    >
                  </div>
                </template>
                <span
                  v-if="
                    allowed_operations.includes('CameraRead') ||
                    allowed_operations == 'All'
                  "
                  >{{ show_heads ? "Turn Off Images" : "Show Images" }}</span
                >
                <span v-else>You don't have permission to view images</span>
              </v-tooltip> -->

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="btn_livestock_add_animal"
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    @click="create_treatment"
                    large
                    color="primary"
                    v-if="allowed_operations == 'WRITE'"
                    >mdi-plus-circle</v-icon
                  >
                  <div v-else v-bind="attrs" v-on="on">
                    <v-icon
                      class="mt-0 pt-0 mr-4"
                      large
                      disabled
                      color="primary"
                      >mdi-plus-circle</v-icon
                    >
                  </div>
                </template>
                <span v-if="allowed_operations == 'WRITE'">Add Treatment</span>
                <span v-else>You don't have permission to add treatments</span>
              </v-tooltip>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="pt-0 mt-0 assign_select assign_select_v font_400 rounded-lg"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                no-data-text="No data available"
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="selected_headers"
                :items="treatments"
                :items-per-page="100"
                class="elevation-1 livestock_table"
                hide-default-footer
                :search="search"
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
                show-expand
                :options.sync="options"
                single-expand
                :expanded.sync="expanded"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div class="oc_table_header">
                    <v-tabs class="table_tabs pt-1" v-model="selected_table">
                      <v-tab data-cy="medical_tab_general">General</v-tab>
                      <!-- <v-tab>Vaccine</v-tab>
                    <v-tab>RX</v-tab> -->
                    </v-tabs>
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </div>
                </template>

                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden">
                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="[
                          'All',
                          { value: 'ANTIBIOTIC', text: 'Antibiotic' },
                          { value: 'ANTIPARASITIC', text: 'Antiparasitic' },

                          { value: 'BRANDING', text: 'Branding' },
                          { value: 'DEHORNING', text: 'Dehorning' },
                          { value: 'EUTHANASIA', text: 'Euthanasia' },
                          { value: 'HOOF_TRIMMING', text: 'Hoof Trimming' },
                          { value: 'MEDICATION', text: 'Medication' },
                          { value: 'STERILIZATION', text: 'Sterilization' },
                          { value: 'SURGICAL', text: 'Surgical' },
                          { value: 'TAGGING', text: 'Tagging' },
                          { value: 'TATTOO', text: 'Tattoo' },
                          { value: 'VACCINATION', text: 'Vaccination' },
                          { value: 'OTHER', text: 'Other' },
                        ]"
                        v-model="filter_type"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      >
                      </v-select>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="px-0">
                    <v-row no-gutters class="mx-0">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="5"
                        xl="4"
                        class="pr-0 pl-0"
                      >
                        <v-data-table
                          :loading="loading"
                          loading-text="Loading... Please wait"
                          :headers="headers_treatment_animals"
                          :items="treatment_animals"
                          style="border-radius: 0"
                          class="elevation-1"
                          hide-default-footer
                          dense
                          :options.sync="animal_options"
                        >
                          <template
                            v-slot:top="{ pagination, options, updateOptions }"
                          >
                            <div style="width: 100%; text-align: left">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    data-cy="btn_treatment_table_add_note"
                                    v-bind="attrs"
                                    :disabled="!(allowed_operations == 'WRITE')"
                                    v-on="on"
                                    class="ml-3 mt-2"
                                    @click="
                                      add_animal_to_treatment_dialog = true
                                    "
                                    large
                                    color="primary"
                                    >mdi-plus-circle</v-icon
                                  >
                                </template>
                                <span>Add Animal to Treatment</span>
                              </v-tooltip>

                              <v-data-footer
                                :pagination="pagination"
                                :options="options"
                                class="table_pagination_notes"
                                @update:options="updateOptions"
                                :items-per-page-options="[10]"
                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                              />
                            </div>
                          </template>
                          <template v-slot:[`item.tag_label`]="{ item }">
                            <v-chip
                              style="
                                height: 24px;
                                margin-bottom: -2px;
                                margin-top: -3px;
                                padding-top: 2px;
                              "
                              v-if="item.tag_label"
                              :color="
                                item.tag_color && item.color != 'UNDEFINED'
                                  ? 'oc_' +
                                    item.tag_color.toLowerCase() +
                                    ' darken-1'
                                  : 'grey darken-1'
                              "
                            >
                              <span style="color: white; font-weight: 500">{{
                                item.tag_label
                              }}</span></v-chip
                            ><span v-else>-</span>
                          </template>
                          <template
                            v-slot:[`item.delete_animal_connection`]="{ item, index }"
                          >
                            <v-icon
                              style="z-index: 10"
                              @click="
                                delete_animal_connection_id = {id: item.id, index: index};
                                delete_animal_connection_dialog = true;
                              "
                              :disabled="
                                allowed_operations != 'WRITE'
                              "
                            >
                              mdi-delete-outline
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="7"
                        xl="8"
                        class="pr-0 pl-0"
                      >
                        <v-data-table
                          data-cy="notes_table_treatment"
                          :loading="loading"
                          loading-text="Loading... Please wait"
                          :headers="headers_notes"
                          :items="item.user_notes"
                          style="border-radius: 0"
                          class="elevation-1"
                          hide-default-footer
                          dense
                        >
                          <template
                            v-slot:top="{ pagination, options, updateOptions }"
                          >
                            <div style="width: 100%; text-align: left">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    data-cy="btn_treatment_table_add_note"
                                    v-bind="attrs"
                                    :disabled="!(allowed_operations == 'WRITE')"
                                    v-on="on"
                                    class="ml-3 mt-2"
                                    @click="
                                      note_dialog = true;
                                      note_text = '';
                                      note_index = item;
                                    "
                                    large
                                    color="primary"
                                    >mdi-plus-circle</v-icon
                                  >
                                </template>
                                <span>Add Note</span>
                              </v-tooltip>

                              <v-data-footer
                                :pagination="pagination"
                                :options="options"
                                class="table_pagination_notes"
                                @update:options="updateOptions"
                                :items-per-page-options="[20]"
                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                              />
                            </div>
                          </template>
                          <template v-slot:[`item.datetime`]="{ item }">
                            {{ item.datetime.split("T")[0] }}
                          </template>

                          <template v-slot:[`item.comment`]="{ index }">
                            <v-edit-dialog
                              @save="update_note(item, index)"
                              @cancel="edit_value_note = null"
                              large
                              @open="
                                edit_value_note = item.user_notes[index].comment
                              "
                            >
                              {{ item.user_notes[index].comment }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="edit_value_note"
                                  label="Note"
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.delete_note`]="{ item, index }">
                        <v-icon
                          style="z-index: 10"
                          @click.stop="
                            delete_note_id = {id: item.id, index: index};
                            delete_note_dialog = true;
                          "
                          :disabled="allowed_operations != 'WRITE'"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </td>
                </template>

                <template v-slot:[`item.date`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'date', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.date, 'date')"
                  >
                    {{ item.date }}
                    <template v-slot:input>
                      <v-date-picker
                        v-model="edit_value"
                        v-if="validateDateEmpty(edit_value) == true"
                        flat
                        no-title
                        event-color="#ff9900"
                        reactive
                      ></v-date-picker>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.booster_date`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'booster_date', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.booster_date, 'date')"
                  >
                    <span v-if="item.booster_date">{{
                      item.booster_date
                    }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-date-picker
                        v-model="edit_value"
                        v-if="validateDateEmpty(edit_value) == true"
                        flat
                        no-title
                        event-color="#ff9900"
                        reactive
                      ></v-date-picker>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.type`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'type', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.type)"
                  >
                    {{ capitalizeFirstLetter(item.type) }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="[
                          { value: 'ANTIBIOTIC', text: 'Antibiotic' },
                          { value: 'ANTIPARASITIC', text: 'Antiparasitic' },

                          { value: 'BRANDING', text: 'Branding' },
                          { value: 'DEHORNING', text: 'Dehorning' },
                          { value: 'EUTHANASIA', text: 'Euthanasia' },
                          { value: 'HOOF_TRIMMING', text: 'Hoof Trimming' },
                          { value: 'MEDICATION', text: 'Medication' },
                          { value: 'STERILIZATION', text: 'Sterilization' },
                          { value: 'SURGICAL', text: 'Surgical' },
                          { value: 'TAGGING', text: 'Tagging' },
                          { value: 'TATTOO', text: 'Tattoo' },
                          { value: 'VACCINATION', text: 'Vaccination' },
                          { value: 'OTHER', text: 'Other' },
                        ]"
                        class="mt-4"
                        outlined
                        dense
                        label="Type"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.reason`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'reason', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.reason)"
                  >
                    <span v-if="item.reason">{{ item.reason }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        label="Reason"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.withdraw_date`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'withdraw_date', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.withdraw_date, 'date')"
                  >
                    <span v-if="item.withdraw_date">{{
                      item.withdraw_date
                    }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-date-picker
                        v-model="edit_value"
                        v-if="validateDateEmpty(edit_value) == true"
                        flat
                        no-title
                        event-color="#ff9900"
                        reactive
                      ></v-date-picker>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.vet_name`]="{ item, index }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'vet_name', index)"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.vet_name)"
                  >
                    <span v-if="item.vet_name">{{ item.vet_name }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        label="Vet Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.drugs`]="{ item, index }">
                  <v-edit-dialog
                    @save="
                      save_record(item.id, 'drugs', index, item.customer_id)
                    "
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.drugs, 'drugs')"
                  >
                    <span v-if="item.drugs"
                      ><v-chip
                        class="mr-1"
                        style="
                          height: 24px;
                          margin-bottom: -4px;
                          margin-top: -5px;
                          padding-top: 2px;
                          padding-left: 5px;
                          padding-right: 5px;
                          border-radius: 9px;
                        "
                        v-for="(drug, index) in item.drugs"
                        :key="index"
                        active
                        >{{ drug.name }} ({{ drug.dose }}ml)</v-chip
                      >
                    </span>

                    <template v-slot:input v-if="edit_value">
                      <v-icon
                        @click="push_empty_drug(item.id)"
                        color="primary"
                        class="mt-3"
                        >mdi-plus-box-outline</v-icon
                      >
                      <v-row v-for="(drug, index) in edit_value" :key="index">
                        <v-col cols="8"
                          ><v-text-field
                            outlined
                            dense
                            v-model="drug.name"
                            label="Name"
                            hide-details
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4"
                          ><v-text-field
                            outlined
                            dense
                            v-model="drug.dose"
                            label="Dose (ml)"
                            hide-details
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
                <v-icon
                  @click.stop="handleExpansion(item, isExpanded)"
                  dense
                  :class="'mr-5'"
                  :color="isExpanded ? 'primary' : 'gray'"
                  >{{
                    isExpanded ? "mdi-note-off-outline" : "mdi-note-outline"
                  }}</v-icon
                >
                <v-icon
                  style="z-index: 10"
                  @click.stop="
                    delete_treatment_id = item.id;
                    delete_dialog = true;
                  "
                  :disabled="allowed_operations != 'WRITE'"
                >
                  mdi-delete-outline
                </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
    <v-dialog v-model="expand_dialog" max-width="1000px">
      <animalCard
        :animal_id="selected_animal_id"
        :animal_index_pos="selected_animal_index_pos"
        :tab_index="4"
      />
    </v-dialog>

    <v-dialog v-model="note_dialog" max-width="800px" min-width="760px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Add Note</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="note_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-text-field
            v-model="note_text"
            label="Note"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="note_dialog = false"> Cancel </v-btn>
          <v-btn color="primary" @click="add_note"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="add_animal_to_treatment_dialog"
      max-width="460px"
      min-width="460px"
    >
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Add Animal to Treatment</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="add_animal_to_treatment_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
          <v-col cols="12">
            <v-autocomplete
              outlined
              dense
              v-model="selected_animals_to_add"
              :items="animals_to_add"
              label="Animals to Add"
              multiple
              :return-object="true"
              :search-input.sync="selection_query_params.search_dam"
            >
              <template v-slot:item="{ item }"
                ><v-chip
                  style="color: white; font-weight: 500; margin-bottom: -5px"
                  small
                  :color="
                    item.tag_color
                      ? 'oc_' + item.tag_color.toLowerCase() + ' darken-1'
                      : 'grey darken-1'
                  "
                  >{{ item.text }}</v-chip
                ></template
              >
            </v-autocomplete>
          </v-col>
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="add_animal_to_treatment_dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="selected_animals_to_add.length == 0"
            @click="add_animals_to_treatment"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="delete_animal_connection_dialog"
      max-width="400px"
      min-width="400px"
    >
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Remove Animal from Treatment?</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_animal_connection_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_animal_connection_dialog = false">
            Cancel
          </v-btn>
          <v-btn color="oc_red white--text" @click="delete_animal_connection">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_dialog" max-width="400px" min-width="400px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Delete Treatment Entry?</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_dialog = false"> Cancel </v-btn>
          <v-btn color="oc_red white--text" @click="delete_treatment">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_note_dialog" max-width="400px" min-width="400px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Delete Note?</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_note_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_note_dialog = false"> Cancel </v-btn>
          <v-btn color="oc_red white--text" @click="delete_note">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <events></events> -->
  </div>
</template>

<script>
import {
  validateDateEmpty,
  validateIsNumber,
} from "@/utilities/ValidationRules";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import animalCard from "@/components/AnimalCard.vue";
import { eventBus } from "../main.js";
import moment from "moment";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    navbar,
    sidenav,
    animalCard,
    //events,
  },
  props: ["filter"],
  data() {
    return {
      loading: false,

      selected_table: 0,
      edit_value: null,
      editable: false,
      expanded: [],
      edit_value_note: null,
      connection_to: "group",

      expand_dialog: false,
      selected_animal_id: null,
      selected_animal_index_pos: null,
      selected_animal_index: null,

      add_animal_to_treatment_dialog: false,

      filter_type: "All",

      note_dialog: false,
      note_text: "",
      note_index: false,

      show_heads: false,

      treatment_animals: [],

      selected_animals_to_add: [],

      delete_animal_connection_dialog: false,
      delete_animal_connection_id: null,

      delete_note_dialog: false,
      delete_note_id: null,

      delete_dialog: false,
      delete_treatment_id: null,

      treatments: [],

      options: {},
      animal_options: { sortBy: ["tag_label"], limit: 10, offset: 0 },
      sort_by: "date",
      sort_desc: true,
      search: "",

      selection_query_params: {
        search: null,
        treatment_id: null,
      },
    };
  },
  methods: {
    validateIsNumber,
    validateDateEmpty,
    handleExpansion(item, state) {
      this.expanded = [];
      if (!state) {
        this.expanded = [item];
      }
    },
    push_empty_drug(treatment_id) {
      if (Array.isArray(this.edit_value)) {
        this.edit_value.push({
          treatment_id: treatment_id,
          id: uuidv4(),
        });
      }
    },
    async delete_treatment() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_treatment_by_pk,
            variables: {
              pk_columns: {
                id: this.delete_treatment_id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.delete_dialog = false;
        this.$store.dispatch("DDB_GET_TREATMENTS", this.query_params).then(() => {
        this.treatments = this.$store.getters.getTreatments;
      });
        eventBus.$emit("add_button_alert", {
          msg: `Successfully deleted the treatment`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error deleting the treatment`,
          err: true,
        });
      }
    },
    async delete_note() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_user_note_by_pk,
            variables: {
              pk_columns: {
                id: this.delete_note_id.id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.expanded[0].notes.splice(this.delete_note_id.index, 1)
        this.delete_note_dialog = false;
        eventBus.$emit("add_button_alert", {
          msg: "Successfully deleting the note",
          err: false,
        });
      } catch (err) {
        console.log(err)
        eventBus.$emit("add_button_alert", {
          msg: "Error deleting the note",
          err: true,
        });
      }
    },
    async delete_animal_connection() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_animal_treatment_by_pk,
            variables: {
              pk_columns: {
                animal_id: this.delete_animal_connection_id.id,
                treatment_id: this.expanded[0].id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        console.log(response)
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.treatment_animals.splice(this.delete_animal_connection_id.index, 1)
        this.delete_animal_connection_dialog = false;
        eventBus.$emit("add_button_alert", {
          msg: `Successfully removed the animal`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error removing the animal`,
          err: true,
        });
      }
    },
    async add_animals_to_treatment() {
      try {
        var insert_animals = [];
        for (const i in this.selected_animals_to_add) {
          insert_animals.push({
            animal_id: this.selected_animals_to_add[i].value,
            treatment_id: this.expanded[0].id,
            customer_id: this.$store.getters.getCustomerID,
          });
        }
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.insert_animal_treatment,
            variables: { objects: insert_animals },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        if (
          this.expanded &&
          this.expanded[0] &&
          this.expanded[0].id &&
          this.query_params_animals.limit
        ) {
          this.$store
            .dispatch("DDB_GET_ANIMALS", this.query_params_animals)
            .then(() => {
              this.treatment_animals = this.$store.getters.getAnimals;
            });
        }
        this.add_animal_to_treatment_dialog = false;
        this.selected_animals_to_add = [];
        eventBus.$emit("add_button_alert", {
          msg: `Successfully added the amimals`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error adding the animals`,
          err: true,
        });
      }
    },
    // download_csv() {
    //   const csvString =
    //     "data:text/csv;charset=utf-8," +
    //     [
    //       [
    //         "Date",
    //         "Type",
    //         "Drug",
    //         "Reason",
    //         "Vet",
    //         "Booster Date",
    //         "Withdraw Days",
    //       ],
    //       ...this.treatments.map((item) => [
    //         item.treatment_date ? item.treatment_date : "-",
    //         item.treatment_type ? item.treatment_type : "-",
    //         item.treatment_administered_drugs &&
    //         item.treatment_administered_drugs[0]
    //           ? item.treatment_administered_drugs[0].type
    //           : "-",
    //         item.treatment_reason ? item.treatment_reason : "-",
    //         item.treatment_vet_name ? item.treatment_vet_name : "-",
    //         item.treatment_booster_date ? item.treatment_booster_date : "-",
    //         item.treatment_withdraw_days ? item.treatment_withdraw_days : "-",
    //       ]),
    //     ]
    //       .map((e) => e.join(","))
    //       .join("\n");
    //   var encodedUri = encodeURI(csvString);
    //   window.open(encodedUri);
    // },
    select_animal(item) {
      var animal = item;
      if (item && item.treatment_date) {
        animal = item.animal;
      }
      if (!this.editable && item) {
        this.selected_animal_id = animal.id;
        this.selected_animal_index = this.animals_items[0].indexOf(animal.id);
        if (this.selected_animal_index == 0) {
          this.selected_animal_index_pos = "first";
        } else if (
          this.selected_animal_index ==
          this.animals_items[0].length - 1
        ) {
          this.selected_animal_index_pos = "last";
        } else {
          this.selected_animal_index_pos = "m";
        }

        this.expand_dialog = true;
      }
    },
    async add_note() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.insert_user_note_one,
            variables: {
              object1: {
                id: uuidv4(),
                customer_id: this.$store.getters.getCustomerID,
                author: this.$store.getters.getUser.username,
                datetime: moment().format("YYYY-MM-DDThh:mm:ss"),
                comment: this.note_text,
                treatment_id: this.note_index.id,
              },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.treatments[
          this.treatments.indexOf(this.note_index)
        ].user_notes.unshift(response.data.data.insert_user_note_one);
        this.note_dialog = false;
        this.note_text = "";
        this.note_index = false;
        eventBus.$emit("add_button_alert", {
          msg: `Successfully created the note`,
          err: false,
        });
        eventBus.$emit("add_button_alert", {
          msg: `Successfully created the note`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error creating the note`,
          err: true,
        });
      }
    },
    async update_note(treatment, index) {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_user_note_by_pk,
            variables: {
              pk_columns: {
                id: treatment.user_notes[index].id,
              },
              _set: { comment: this.edit_value_note },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        this.treatments[this.treatments.indexOf(treatment)].user_notes[
          index
        ].comment = response.data.data.update_user_note_by_pk.comment;
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated the note`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating the note`,
          err: true,
        });
      }
    },
    capitalizeFirstLetter(string) {
      if (string) {
        if (
          string == "UNDEFINED" ||
          string == "Undefined" ||
          !string ||
          string == ""
        ) {
          return "-";
        }
        return (string.charAt(0) + string.slice(1).toLowerCase()).replaceAll(
          "_",
          " "
        );
      }
      return "";
    },
    async save_record(id, field, index, customer_id) {
      try {
        var set_params = {};
        if (field == "drugs") {
          for (const i in this.edit_value) {
            this.edit_value[i].customer_id = customer_id;
            this.edit_value[i].treatment_id = id;
          }
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_drug,
              variables: {
                objects: this.edit_value,
                update_columns: ["name", "dose"],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.treatments[index][field] =
            response.data.data.insert_drug.returning;
        } else {
          set_params[field] = this.edit_value;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_treatment_by_pk,
              variables: {
                pk_columns: { id: id },
                _set: set_params,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.treatments[index][field] =
            response.data.data.update_treatment_by_pk[field];
        }

        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated the treatment`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating the treatment`,
          err: true,
        });
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value, type) {
      if (type == "date") {
        if (!value) {
          this.edit_value = moment().format("YYYY-MM-DD");
        } else {
          this.edit_value = value;
          return false;
        }
      } else if (type == "drugs") {
        this.edit_value = JSON.parse(JSON.stringify(value));
      } else {
        if (typeof value == "object") {
          this.edit_value = { ...value };
        } else {
          this.edit_value = value;
        }
      }
    },

    create_treatment() {
      eventBus.$emit("add_treatment_open");
    },
  },
  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    animals_to_add() {
      var selection_animals = [];
      for (const i in this.$store.getters.getSelectionAnimals) {
        selection_animals.push({
          value: this.$store.getters.getSelectionAnimals[i].id,
          text: this.$store.getters.getSelectionAnimals[i].tag_label,
          tag_color: this.$store.getters.getSelectionAnimals[i].tag_color,
        });
      }
      return selection_animals;
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      if (this.customer_id) {
        return {
          sort: this.options.sortBy ? this.options.sortBy[0] : "date",
          sort_direction: this.sort_direction,
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          filter: {
            type: this.filter_type,
          },
        };
      }
      return undefined;
    },
    query_params_animals() {
      return {
        sort: this.animal_options.sortBy
          ? this.animal_options.sortBy[0]
          : "tag_label",
        sort_direction:
          this.animal_options.sortDesc && this.animal_options.sortDesc[0]
            ? "desc"
            : "asc",
        limit: this.animal_options.itemsPerPage,
        offset:
          (this.animal_options.page - 1) * this.animal_options.itemsPerPage,
        treatment_id:
          this.expanded && this.expanded[0] ? this.expanded[0].id : null,
      };
    },
    enums() {
      var enums = {};
      enums.treatment_type = this.$store.getters.getEnums.treatment_type
        ? this.$store.getters.getEnums.treatment_type
        : [];
      enums.drug_administration_type = this.$store.getters.getEnums
        .drug_administration_type
        ? this.$store.getters.getEnums.drug_administration_type
        : [];
      enums.drug_type = this.$store.getters.getEnums.drug_type
        ? this.$store.getters.getEnums.drug_type
        : [];

      return enums;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    cookie_bucket_footage() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    animals_items() {
      var animal_ids = [];
      for (const n in this.$store.getters.getAnimals) {
        animal_ids.push(this.$store.getters.getAnimals[n].id);
      }
      return [animal_ids, this.$store.getters.getAnimals];
    },
    selected_headers() {
      if (this.selected_table == 0) {
        return this.headers_general;
      }
      return this.headers_general;
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },

    headers_general() {
      return [
        {
          text: "Date",
          value: "date",
          cellClass: this.editable ? "non-hl" : "no_edit",
          sort: () => {
            return false;
          },
        },

        {
          text: "Type",
          value: "type",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "220px",
        },
        {
          text: "Drugs",
          value: "drugs",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "320px",
        },
        {
          text: "Reason",
          value: "reason",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Vet",
          value: "vet_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        {
          text: "Booster Date",
          value: "booster_date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Withdraw Date",
          value: "withdraw_date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        { text: "", value: "data-table-expand", width: "96px" },
      ];
    },
    headers_notes() {
      return [
        {
          text: "Date",
          value: "datetime",
          width: "120px",
        },
        {
          text: "Note",
          value: "comment",
          cellClass: "non-hl",
        },
        {
          text: "",
          value: "delete_note",
          width: "40px",
        },
      ];
    },
    headers_treatment_animals() {
      return [
        {
          text: "Animal",
          value: "tag_label",
        },
        {
          text: "",
          value: "delete_animal_connection",
          width: "40px",
        },
      ];
    },
    animals() {
      var animals = [];
      if (this.$store.getters.getAnimals.length > 0) {
        for (const i in this.$store.getters.getAnimals) {
          animals.push({
            value: this.$store.getters.getAnimals[i].id,
            text: this.$store.getters.getAnimals[i].tag
              ? this.$store.getters.getAnimals[i].tag.animal_tag_text
              : this.$store.getters.getAnimals[i].animal_name,
            color: this.$store.getters.getAnimals[i].tag
              ? this.$store.getters.getAnimals[i].tag.animal_tag_color
              : "",
          });
        }
      }
      return animals;
    },
  },

  created() {
    eventBus.$on("get_treatments", () => {
      this.$store.dispatch("DDB_GET_TREATMENTS", this.query_params).then(() => {
        this.treatments = this.$store.getters.getTreatments;
      });
    });
  },
  watch: {
    selection_query_params: {
      handler: function () {
        if (this.selection_query_params.treatment_id) {
          this.$store.dispatch(
            "DDB_GET_SELECTION_ANIMALS",
            this.selection_query_params
          );
        }
      },
      deep: true,
    },
    query_params() {
      if (this.query_params) {
        this.$store
          .dispatch("DDB_GET_TREATMENTS", this.query_params)
          .then(() => {
            this.treatments = this.$store.getters.getTreatments;
          });
      }
    },
    query_params_animals() {
      this.treatment_animals = [];
      if (
        this.expanded &&
        this.expanded[0] &&
        this.expanded[0].id &&
        this.query_params_animals.limit
      ) {
        this.$store
          .dispatch("DDB_GET_ANIMALS", this.query_params_animals)
          .then(() => {
            this.treatment_animals = this.$store.getters.getAnimals;
          });
      }
    },
    delete_dialog() {
      if (!this.delete_dialog) {
        this.delete_treatment_id = null;
      }
    },
    add_animal_to_treatment_dialog() {
      if (this.add_animal_to_treatment_dialog) {
        this.selection_query_params.treatment_id = this.expanded[0].id;
      }
    },

    expand_dialog() {
      if (!this.expand_dialog) {
        this.selected_animal_id = null;
        this.selected_animal_index_pos = null;
        this.selected_animal_index = null;
      }
    },

    treatments() {
      this.loading = false;
    },
  },
};
</script>
<style lang="css">
</style>